























































































































































































































































































































































































































































































































  import { Component, Vue } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL, EventBus,ENV } from "../../config";
  import { authHeader } from "../../services/auth";
  import { PerfectScrollbar } from "vue2-perfect-scrollbar";
  import ApexChart from "vue-apexcharts";
  import CommonUserChoiceModal from "../CommonUserChoiceModal.vue";
  import datepicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import saveAs from "save-as";
    import moment from "moment";

  import {
    PRIOR_PROPERTY_USAGE_TYPE,
    PRIOR_PROPERTY_TITLE_TYPE
  } from "../../constants/constants";
  
  @Component({
    components: {
      PerfectScrollbar,
      ApexChart,
      CommonUserChoiceModal,
      datepicker
    },
  })
  export default class LoanList extends Vue {
    /* eslint-disable */
    public allLoan: any = [];
    public remarks: any = "";
    public loanId: any = null;
    public status: number = 0;
    public documents: any = [];
    public file: any = null;
    public lockExp: any = {
      status: "",
      note: "",
    };
    public closeingDate: any = {
      status: "",
      note: "",
    };
    public formData: any = new FormData();
    public skip = 0;
    public disclosureData: any = {};
    public totalLength = 0;
    public currentLengthOnScroll = 0;
    public roleId: any = [];
    public loanpurpose: any = "";
    public statusParam: any = "inProcess";
    public searchParam = "";
    public searchKeyword = "";
    public showLoader = false;
    public lockDateStatus = false;
    public closingDateStatus = false;
    public lockDatedata: any = {};
    public error = false;
    public updatedSubmissionDate = null;
    public sentToProcessingRemark = null
    public loanid = null
    public priorPropertyUsageType = PRIOR_PROPERTY_USAGE_TYPE;
    public priorPropertyTitleType = PRIOR_PROPERTY_TITLE_TYPE;
    public fieldName: any = "";
    public sortingType: any = 1
    public chartOptions = {
      colors: ["#00bc00"],
      chart: {
        type: "radialBar",
        offsetY: -10,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ["Average Results"],
  };
  public sortType = null;
  public sortParam = null;
  public ENV = ENV

  public async openManufacturedHomeModal(loanId, messgae, isIbtsRequired, isEngineerRequired) {
    await this.$refs.refrence["openManufacturedHomeModal"]({loanId, messgae, isIbtsRequired, isEngineerRequired})
  }

  public async openDisclosureNotFoundModal(loanId){
    await this.getDisclosureRemarkInfo(loanId)
    await this.$refs.refrence["openDisclosureNotFoundModal"]({loanId, disclosureData: this.disclosureData.disclosureRemarks, })
  }

  /**
   * Exporting xml file
   */
   public async exportXmlFile(loanId) {
    try {
      const response = await Axios.post(
        BASE_API_URL + "broker/exportXmlFile",
        { loanId: loanId },
        {
          headers: authHeader(),
        }
        );
      if (response.data.xmlData) {
        const dom = document.createElement("a");
        const primaryBorrowerName = response.data.primaryBorrower;
        const filename = primaryBorrowerName + "-" + loanId + ".xml";
        const blob = new Blob([response.data.xmlData], { type: "text/plain" });
        dom.setAttribute("href", window.URL.createObjectURL(blob));
        dom.setAttribute("download", filename);
        dom.dataset.downloadurl = ["text/plain", dom.download, dom.href].join(
          ":"
          );
        dom.draggable = true;
        dom.classList.add("dragout");
        dom.click();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getAllLoans(mountCall) {
    mountCall
    ? (this.$store.state.tmpcoLoader = true)
    : (this.showLoader = true);
    try {
      this.skip = mountCall ? 0 : this.skip + 15;
      let path = "";
      path =
      this.$store.state.sessionObj.userType == 2
      ? "broker/dashboard/allLoansList"
      : "processor/dashboard/getAllocatedLoans";
      let response = await Axios.get(BASE_API_URL + path, {
        params: {
          statusParam: this.statusParam,
          searchParam: this.searchParam.trim(),
          searchKeyword: this.searchKeyword.trim(),
          sortType: this.sortType,
          sortParam: this.sortParam,
          options: {
            skip: this.skip,
            limit: 15,
          },
        },
        headers: authHeader(),
      });
      if (response.status === 201) {
        if (mountCall) {
          this.allLoan = response.data.promise ? response.data.promise : [];
          this.loanpurpose = response.data.loanpurpose
          ? response.data.loanpurpose
          : null;
        } else {
          let data = response.data.promise ? response.data.promise : [];
          this.allLoan = this.allLoan.concat(data);
          this.loanpurpose = response.data.loanpurpose;
        }
        this.allLoan.forEach((l) => {
          let disclosure = l.workflow.filter(
            (w) => w.workflowTaskId == 1 && w.completedOn != null
            );
          if (disclosure.length > 0) {
            l["disclosureInitiated"] = true;
          } else {
            l["disclosureInitiated"] = false;
          }
        });
        this.sortAccordingly(this.fieldName, this.sortingType)
        this.totalLength =
        this.$store.state.sessionObj.userType == 2
        ? response.data.allLoansLength
        : response.data.totalLoans;
        this.currentLengthOnScroll = this.allLoan ? this.allLoan.length : 0;
        this.$emit("total-loan", this.totalLength);
      }
    } catch (error) {
      console.log(error);
    }
    mountCall
    ? (this.$store.state.tmpcoLoader = false)
    : (this.showLoader = false);
  }

  public searchList() {
    try {
      if (this.searchParam.trim() != "" && this.searchKeyword.trim() != "") {
        this.getAllLoans(true);
      } else {
        this.searchParam.trim() == ""
        ? this.$snotify.error("Select a parameter to search")
        : this.searchKeyword.trim() == ""
        ? this.$snotify.error("Write something before search")
        : "";
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getDisclosureRemarkInfo(loanId) {
    //this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL + "broker/dashboard/getDisclosureRemarkInfo/" + loanId,
        { headers: authHeader() }
        );
      if (response.status === 201) {
        this.disclosureData = JSON.parse(JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }
    //  this.callLoader(false, null);
  }

  public resetList() {
    try {
      this.searchParam = "";
      this.searchKeyword = "";
      this.statusParam = "inProcess";
      this.getAllLoans(true);
    } catch (error) {
      console.log(error);
    }
  }

  public addLockExp(data, lockdata) {
    this.loanId = data;
    this.lockExp = {
      status: "",
      note: "",
    };
    this.$modal.show("lockExp");
  }

  public addclosingDate(data, closeingdata) {
    this.loanId = data;
    this.closeingDate = {
      status: "",
      note: "",
    };
    this.$modal.show("closeingdate");
  }

  public async scrollHandle(evt) {
    let scroll: any = this.$refs.scrollbar;
    if (
      !this.showLoader &&
      scroll.ps.reach.y == "end" &&
      // x.scrollTop + x.clientHeight <= x.scrollHeight &&
      this.currentLengthOnScroll < this.totalLength
      ) {
      await this.getAllLoans(false);
  }
}

// public getloanId(loanId, status) {
//   this.loanId = loanId;
//   this.status = status.status;
//   this.remarks = status.remarks;
//   this.$modal.show("loan-status-modal");
// }

// public async changeLoanStatus(data) {
//   try {
//     if(data){
//      this.status = 0
//      this.$snotify.info("No Changes")
//      this.$modal.hide("update-loan-status-modal")
//      return
//    }
//    this.remarks = this.status == 1 ? this.remarks : null;
//    let response = await Axios.post(
//     BASE_API_URL + "processor/dashboard/changeLoanStatus",
//     { remarks: this.remarks, status: this.status, loanId: this.loanId },
//     {
//       headers: authHeader(),
//     }
//     );
//    if (response.status == 201) {
//     this.status = 0
//     await this.getAllLoans(true);
//     this.$snotify.success(response.data.message);
//     this.$modal.hide("update-loan-status-modal");
//   }
// } catch (error) {
//   console.log(error);
// }
// }

public async getFeeAndPricingDocument(loanId) {
  let res = await Axios.post(
    BASE_API_URL + "broker/getFeeAndPricingDocument",
    {
      loanId: loanId,
    },
    {
      headers: authHeader(),
    }
    );
  this.documents = res.data.documents;
  this.loanId = loanId;
  this.$modal.show("upload-modal");
}

public appendData(event, documentId) {
  let files = event.target.files;
  let docDetails: any = {
    loanId: this.loanId,
    documentId: documentId,
  };
  this.formData.append("loanId", this.loanId);
  this.formData.append("documentId", documentId);
    // this.formData.append("docDetails", docDetails);
    Array.from(files).forEach((f) => {
      this.formData.append("file", f);
    });
  }

  // public async uploadDocument() {
  //   try {
  //     let response = await Axios.post(
  //       BASE_API_URL + "broker/uploadFeeAndPricingDoc",
  //       this.formData,
  //       {Lock Expiration has Changed 05/13/2021
  //         headers: authHeader(),
  //       }
  //     );
  //     if (response.status == 201) {
  //       this.$snotify.success("Document has been uploaded.");
  //       this.$bvModal.hide("upload-modal");
  //       this.formData = new FormData();
  //       this.getAllLoans(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public async getProcessorType() {
    let res = await Axios.get(
      BASE_API_URL + "processor/dashboard/getProcessorType",
      {
        headers: authHeader(),
      }
      );
    this.roleId = res.data.roleId;
  }

  created() {
    EventBus.$on("refres-loan-list", async () => {
      await this.getAllLoans(true);
    });
  }

  public async addLockExpDetails() {
    try {
      if (this.lockExp.status == "Update" && this.lockExp.note == "") {
        this.error = true;
      } else {
        let res = await Axios.post(
          BASE_API_URL + "processor/dashboard/addLockExpDetails",
          {
            loanId: this.loanId,
            ...this.lockExp,
          },
          {
            headers: authHeader(),
          }
          );
        if ((res.status = 201)) {
          this.$modal.hide("lockExp");
          this.$snotify.success("Lock Expention Details Added Successfully .");
          this.error = false;
          await this.getAllLoans(true);
          this.lockDateStatus = true;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async addCloseingDateDetails() {
    try {
      if (
        this.closeingDate.status == "Update" &&
        this.closeingDate.note == ""
        ) {
        this.error = true;
    } else {
      let res = await Axios.post(
        BASE_API_URL + "processor/dashboard/addClosingDateDetails",
        {
          loanId: this.loanId,
          ...this.closeingDate,
        },
        {
          headers: authHeader(),
        }
        );
      if ((res.status = 201)) {
        this.$modal.hide("closeingdate");
        this.$snotify.success("Closing Date Details Added Successfully .");
        this.error = false;
        await this.getAllLoans(true);
        this.closingDateStatus = true;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

public sortAccordingly(fieldName, type) {
  if (type == 1) {
    this.allLoan.sort((a, b) => {
      let fa;
      let fb;
      if (fieldName == "BrokerCompanyName") {
        (fa = a.brokerCompanyName.toLowerCase()),
        (fb = b.brokerCompanyName.toLowerCase());
      } else if (fieldName == "BrokerName") {
        (fa = a.broker.firstName.toLowerCase()),
        (fb = b.broker.firstName.toLowerCase());
      } else if (fieldName == "LoanType") {
        (fa = a.loanpurpose.loanPurpose.name.toLowerCase()),
        (fb = b.loanpurpose.loanPurpose.name.toLowerCase());
      } else if (fieldName == "status") {
        (fa = a.mileStone ? a.mileStone.toLowerCase() : ""),
        (fb = b.mileStone ? b.mileStone.toLowerCase() : "")
      } else if (fieldName == "LockExp") {
        fa = new Date(
          a.closingDate && a.closingDate.length > 0
          ? a.closingDate[a.closingDate.length - 1].date
          : null
          );
        fb = new Date(
          b.closingDate && b.closingDate.length > 0
          ? b.closingDate[b.closingDate.length - 1].date
          : null
          );
      }else if(fieldName == "SrProcessorName") {
        (fa = a.srProName ? a.srProName.toLowerCase() : ""),
        (fb = b.srProName ? b.srProName.toLowerCase() : "")
      }
      if (fb > fa) {
        return -1;
      }
      return 0;
    });
  } else if (type == -1) {
    this.allLoan.sort((a, b) => {
      let fa;
      let fb;
      if (fieldName == "BrokerCompanyName") {
        (fa = a.brokerCompanyName.toLowerCase()),
        (fb = b.brokerCompanyName.toLowerCase());
      } else if (fieldName == "BrokerName") {
        (fa = a.broker.firstName.toLowerCase()),
        (fb = b.broker.firstName.toLowerCase());
      } else if (fieldName == "LoanType") {
        (fa = a.loanpurpose.loanPurpose.name.toLowerCase()),
        (fb = b.loanpurpose.loanPurpose.name.toLowerCase());
      } else if (fieldName == "status") {
        (fa = a.mileStone ? a.mileStone.toLowerCase() : ""),
        (fb = b.mileStone ? b.mileStone.toLowerCase() : "")
      } else if (fieldName == "LockExp") {
        fa = new Date(
          a.closingDate && a.closingDate.length > 0
          ? a.closingDate[a.closingDate.length - 1].date
          : null
          );
        fb = new Date(
          b.closingDate && b.closingDate.length > 0
          ? b.closingDate[b.closingDate.length - 1].date
          : null
          );
      }
      if (fb < fa) {
        return -1;
      }
      return 0;
    });
  }
}


public async updateSubmissionDate() {
 try{
   let res = await Axios.post(
    BASE_API_URL + "processor/dashboard/updateSubmissionDate",
    {
      loanId: this.loanId,
      submissionDate: this.updatedSubmissionDate,
      sentToProcessingRemark: this.sentToProcessingRemark
    },
    {
      headers: authHeader(),
    }
    );
   if(res) {
    this.sentToProcessingRemark = null
    this.getAllLoans(true);
    this.$modal.hide('update-submission-modal')
    this.$snotify.success('Submission date updated successfully')
  }

} catch(error){
 console.log(error)
}
}





async mounted() {
  await this.getAllLoans(true);
  if (this.$store.state.sessionObj.userType == 3)
    await this.getProcessorType();
}
/* eslint-disable */
}
